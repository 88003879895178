.MuiTypography-h2 {
  color: #6f6c6c;
  font-size: 4rem;
  font-weight: bold;
}

.MuiTypography-body1 {
  color: black;
  font-size: 2rem;
  line-height: 1.5;
}

.MuiDrawer-paper {
  width: 250px;
  background-color: #f5f5f5;
}

@media (max-width: 600px) {
  .MuiDrawer-paper {
    position: absolute;
    top: 0;
  }
}

.MuiToolbar-root {
  background-color: rgb(0, 0, 0);
}

.App {
  background: linear-gradient(
    180deg,
    rgb(16, 13, 28),
    rgb(4, 7, 15),
    rgb(16, 13, 28)
  );
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 250ms ease-in forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
